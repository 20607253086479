export * from './connect';

export enum socialsNameEnum {
  twitter = 'twitter',
  telegram = 'telegram',
  web = 'web',
  discord = 'discord',
  github = 'github',
  gitbook = 'gitbook',
  medium = 'medium',
}
export interface IProjectCard {
  id: number;
  name: string;
  description: string;
  image: string | null;
  currencyLogo: string;
  userAvatar: string;
  status: string;
  access: string;
  currency: string;
  allocation: number | string;
  registrationOpens: string;
  maxRaise: number;
  votersCount: number;
  round: number | string;
  user: any;
  totalRaised: string;
  pricePerToken: string;
  currentRaised: number;
}
export interface IProject {
  img: string;
  status: string;
  access: string;
  name: string;
  price: string | number;
  softcap?: string | number;
  hardcap: string | number;
  startDate: number;
  voteFor: number;
  voteAgainst: number;
  currency: string;
  goal: number;
  poolSize: string;
  sold: string | number;
  socials?: {
    [key in socialsNameEnum]?: string;
  };
}

export interface ILevel {
  name: string;
  value: string;
  weight: number;
  lottery?: number;
}

export interface IModalProps {
  className?: string;
  visible: boolean;
  onClose: () => void;
}

export interface IApiPresale {
  address: string;
  approveCalled: TNullable<boolean>;
  closeTime: number;
  closeTimeVoting: number;
  creator: string;
  customer: string;
  description: string;
  discord: string;
  eachPoolShare: string;
  email: string;
  features: string;
  feePercent: TNullable<number>;
  feeReceiver: string;
  github: string;
  hardCap: string;
  howDo: string;
  id: number;
  initialized: boolean;
  intermediateRaisedAmount: string;
  investors: string;
  liquidityAllocationTime: number;
  liquidityPercentageAllocation: number;
  listingPrice: string;
  lockPurpose: string;
  lockingProvider: string;
  logo: string;
  lpAddress: string;
  lpTokensLockDurationInDays: number;
  lpUnlockTime: string;
  marketingPlan: string;
  medium: string;
  name: string;
  network: string;
  openTime: number;
  presaleImage: string;
  proofLink: string;
  raisedAmount: string;
  revenueGeneration: string;
  roundNum: string;
  softCap: string;
  story: string;
  teamAndAdvisors: string;
  techStack: string;
  telegram: string;
  telegramUsername: string;
  ticker: string;
  title: string;
  tokenAddress: string;
  tokenContractAddress: string;
  tokenPrice: string;
  tokenReleaseSchedule: string;
  tokensForLiquidityLeft: string;
  tokensForSaleLeft: string;
  totalRegistered: string;
  transactionHash: string;
  twitter: string;
  type: string;
  unsoldTokenToAddress: string;
  usdToLiq: string;
  votes: number[];
  votingParamsMinimum: string;
  votingParamsThreshold: string;
  website: string;
  whitelist: string;
  whitepaper: string;
  withdrawedFunds: boolean;
}

export interface IPresale {
  id: number;
  isInit: boolean;
  status: string;
  round: string;
  stage: number;
  type: string;
  creator: string;
  tokenAddress: string;
  title: string;
  description: string;
  story: string;
  img: string;
  logo: string;
  socials: IPresaleSocials;
  address: string;
  tokenPrice: string;
  tokenSymbol: string;
  tokenDecimals: number;
  totalVoters: number;
  voters: number[];
  registrationOpens: number;
  registrationClosed: number;
  totalRegistered: number;
  salesStart: number;
  salesEnd: number;
  closeTimeVoting: number;
  moonEnd: number;
  diamondEnd: number;
  paperEnd: number;
  stagesEnd: number[];
  roundBreak: number;
  totalRaised: string;
  hardCap: string;
  softCap: string;
  tokensForSaleLeft: number;
  liquidityAdded: number;
  addLiquidityLocked: boolean;
  isCreatorWithdrawFunds: boolean;
  isCreatorReturnTokens: boolean;
  isCreatorClaimedLp: boolean;
  user: IPresaleUser;
}

export interface IPresaleUser {
  isUserWinLottery: boolean;
  isUserRegistered: boolean;
  isUserVoted: boolean;
  level: number;
  investments: {
    amountEth: string;
    amountTokens: string;
    amountClaimed: string;
    readyToClaim: string;
    canUserClaim: boolean;
    canUserInvest: boolean;
    maxUserInvestment: string;
  };
}
interface IPresaleSocials {
  web: string;
  twitter: string;
  telegram: string;
  discord: string;
  medium: string;
}

export interface IFilters {
  status?: string;
  network?: string;
  type?: string;
  ordering?: string;
  page: number;
}

export enum statusEnum {
  voting = 'voting',
  votingFailed = 'voting failed',
  register = 'register',
  registerClosed = 'registration-closed',
  upcoming = 'upcoming',
  open = 'open',
  completedFail = 'completed fail',
  completedSuccess = 'completed success',
}

export type TNullable<T> = T | null;
export type TOptionable<T> = T | undefined;
