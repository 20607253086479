import { useCallback, useEffect, useState, VFC } from 'react';

import cn from 'classnames';
import SwiperCore, { EffectCoverflow, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { convertQuackTokens } from 'utils';

import { ILevel } from 'types';

import 'swiper/swiper.scss';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';
import 'swiper/components/navigation/navigation.scss';
import s from './LevelsSlider.module.scss';

SwiperCore.use([EffectCoverflow, Navigation]);

interface ILevelsSteps {
  items: ILevel[];
  userLevel: number;
}

const LevelsSlider: VFC<ILevelsSteps> = ({ items, userLevel }) => {
  const [swiper, setSwiper] = useState();
  const [windowSize] = useState(window.innerWidth);
  const handleSetSwiper = useCallback((currentSwiper) => {
    setSwiper(currentSwiper);
  }, []);
  useEffect(() => {
    if (swiper) {
      let level;
      if (userLevel === 0) {
        level = userLevel;
      } else {
        level = userLevel - 1;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      swiper.slideToLoop(level);
    }
  }, [swiper, userLevel]);
  return (
    <div className={s.slider_wrapper}>
      <Swiper
        effect="coverflow"
        grabCursor
        centeredSlides
        loop
        navigation
        slidesPerView="auto"
        coverflowEffect={{
          rotate: 0,
          stretch: windowSize > 576 ? 30 : -15,
          depth: windowSize > 576 ? 500 : 0,
          modifier: 1,
          slideShadows: windowSize > 576,
        }}
        className={s.l_slider}
        onSwiper={(currentSwiper) => handleSetSwiper(currentSwiper)}
      >
        {items.map((level, index) => (
          <SwiperSlide className={s.l_slider__slide} key={level.name}>
            <div
              className={cn(s.l_slider__card, {
                [s.l_slider__card_lottery]: level.lottery,
              })}
            >
              <div className={s.l_slider__card__title}>Level {index + 1}</div>
              <div
                className={cn(s.l_slider__card__tag, {
                  [s.l_slider__card__tag_lottery]: level.lottery,
                })}
              >
                {level.lottery ? 'Lottery' : 'Guaranteed'}
              </div>
              <div className={s.l_slider__card__numb}>{index + 1}</div>
              <div className={s.l_slider__card__name}>{level.name}</div>
              <div className={cn(s.l_slider__card__value, 'text-orange')}>
                {index === 0 || index === 1
                  ? convertQuackTokens(level.value, false)
                  : `${level.weight}T`}
              </div>
              <div className={s.l_slider__card__curr}>QUACK</div>
              <div className={s.l_slider__card__info}>
                <span>{level.weight}</span> pool weight
              </div>
              {level.lottery ? (
                <div className={s.l_slider__card__info}>
                  <span>{level.lottery}%</span> win chance
                </div>
              ) : null}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default LevelsSlider;
