import { Levels, Page404 } from 'pages';

import menuPools from '../assets/img/icons/menu_pools.svg';

export { default as RouteManager } from './RouteManager';

export const routes = [
  // {
  //   name: 'CreateProject',
  //   path: '/start/create/:type',
  //   component: <CreateProject />,
  // },
  // {
  //   name: 'ProjectType',
  //   path: '/start/type',
  //   component: <ProjectType />,
  // },
  // {
  //   name: 'Project',
  //   path: '/launchpad/:presaleId/',
  //   component: <Project />,
  // },
  {
    name: 'LaunchPAD',
    path: '/pools',
    props: {
      menu: true,
      icon: menuPools,
      external: false,
    },
    children: [
      // {
      //   name: 'Discover',
      //   path: '/',
      //   component: <Discover />,
      // },
      // {
      //   name: 'Start Raising',
      //   path: '/start',
      //   component: <StartProject />,
      // },
      {
        name: 'Levels',
        path: '/levels',
        component: <Levels />,
      },
    ],
  },
  // {
  //   name: 'Voting',
  //   path: '/voting',
  //   component: <Voting />,
  //   props: {
  //     menu: true,
  //     icon: menuVoting,
  //     external: false,
  //   },
  // },
  // {
  //   name: 'Staking',
  //   path: '/v1staking',
  //   props: {
  //     menu: true,
  //     icon: menuStaking,
  //     external: true,
  //   },
  //   children: [
  //     {
  //       name: 'Staking V1',
  //       path: '/v1staking',
  //       component: <Staking />,
  //     },
  //     {
  //       name: 'Staking V2',
  //       path: 'https://staking.richquack.com/',
  //     },
  //   ],
  // },
];
export const page404 = {
  name: 'page404',
  path: '*',
  component: <Page404 />,
};
