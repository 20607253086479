export default [
  {
    inputs: [
      { internalType: 'address', name: 'link', type: 'address' },
      { internalType: 'address', name: 'staking', type: 'address' },
      { internalType: 'address', name: 'approver', type: 'address' },
      { internalType: 'address', name: 'back', type: 'address' },
    ],
    stateMutability: 'nonpayable',
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [{ indexed: true, internalType: 'address', name: 'back', type: 'address' }],
    name: 'BackendSetted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'pub', type: 'address' },
      { indexed: true, internalType: 'address', name: 'inq', type: 'address' },
      { indexed: true, internalType: 'address', name: 'priv', type: 'address' },
    ],
    name: 'CloneTemplatesSetted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'address', name: 'receiver', type: 'address' },
      { indexed: false, internalType: 'uint256', name: 'percent', type: 'uint256' },
    ],
    name: 'FeeParamsSetted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'back',
        type: 'address',
      },
    ],
    name: 'BackendSetted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'pub',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'inq',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'priv',
        type: 'address',
      },
    ],
    name: 'CloneTemplatesSetted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'receiver',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'percent',
        type: 'uint256',
      },
    ],
    name: 'FeeParamsSetted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'enum PresaleFactory.PresaleTypes',
        name: 'pType',
        type: 'uint8',
      },
      { indexed: false, internalType: 'address', name: 'creator', type: 'address' },
      { indexed: false, internalType: 'address', name: 'presaleAddress', type: 'address' },
      { indexed: false, internalType: 'address', name: 'tokenAddress', type: 'address' },
    ],
    name: 'PresaleCreated',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { indexed: true, internalType: 'bytes32', name: 'previousAdminRole', type: 'bytes32' },
      { indexed: true, internalType: 'bytes32', name: 'newAdminRole', type: 'bytes32' },
    ],
    name: 'RoleAdminChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { indexed: true, internalType: 'address', name: 'account', type: 'address' },
      { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
    ],
    name: 'RoleGranted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { indexed: true, internalType: 'address', name: 'account', type: 'address' },
      { indexed: true, internalType: 'address', name: 'sender', type: 'address' },
    ],
    name: 'RoleRevoked',
    type: 'event',
  },
  {
    inputs: [],
    name: 'APPROVER_ROLE',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'STAKING',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'back', type: 'address' }],
    name: 'changeBackendAddress',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'creator', type: 'address' },
          { internalType: 'address', name: 'tokenAddress', type: 'address' },
          { internalType: 'uint256', name: 'tokenPrice', type: 'uint256' },
          { internalType: 'uint256', name: 'hardCap', type: 'uint256' },
          { internalType: 'uint256', name: 'softCap', type: 'uint256' },
          { internalType: 'uint256', name: 'openTime', type: 'uint256' },
          { internalType: 'uint256', name: 'closeTime', type: 'uint256' },
          { internalType: 'address', name: 'unsoldTokenToAddress', type: 'address' },
        ],
        internalType: 'struct IStructs.PresaleInfo',
        name: '_info',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'uint256', name: 'listingPrice', type: 'uint256' },
          { internalType: 'uint256', name: 'lpTokensLockDurationInDays', type: 'uint256' },
          { internalType: 'uint8', name: 'liquidityPercentageAllocation', type: 'uint8' },
          { internalType: 'uint256', name: 'liquidityAllocationTime', type: 'uint256' },
        ],
        internalType: 'struct IStructs.PresaleDexInfo',
        name: '_dexInfo',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'uint8', name: 'vestingPerc1', type: 'uint8' },
          { internalType: 'uint8', name: 'vestingPerc2', type: 'uint8' },
          { internalType: 'uint256', name: 'vestingPeriod', type: 'uint256' },
        ],
        internalType: 'struct IStructs.VestingInfo',
        name: '_vestInfo',
        type: 'tuple',
      },
    ],
    name: 'createPresaleInqubator',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'creator', type: 'address' },
          { internalType: 'address', name: 'tokenAddress', type: 'address' },
          { internalType: 'uint256', name: 'tokenPrice', type: 'uint256' },
          { internalType: 'uint256', name: 'hardCap', type: 'uint256' },
          { internalType: 'uint256', name: 'softCap', type: 'uint256' },
          { internalType: 'uint256', name: 'openTime', type: 'uint256' },
          { internalType: 'uint256', name: 'closeTime', type: 'uint256' },
          { internalType: 'address', name: 'unsoldTokenToAddress', type: 'address' },
        ],
        internalType: 'struct IStructs.PresaleInfo',
        name: '_info',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'uint256', name: 'listingPrice', type: 'uint256' },
          { internalType: 'uint256', name: 'lpTokensLockDurationInDays', type: 'uint256' },
          { internalType: 'uint8', name: 'liquidityPercentageAllocation', type: 'uint8' },
          { internalType: 'uint256', name: 'liquidityAllocationTime', type: 'uint256' },
        ],
        internalType: 'struct IStructs.PresaleDexInfo',
        name: '_dexInfo',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'uint8', name: 'vestingPerc1', type: 'uint8' },
          { internalType: 'uint8', name: 'vestingPerc2', type: 'uint8' },
          { internalType: 'uint256', name: 'vestingPeriod', type: 'uint256' },
        ],
        internalType: 'struct IStructs.VestingInfo',
        name: '_vestInfo',
        type: 'tuple',
      },
      { internalType: 'address[]', name: '_whitelist', type: 'address[]' },
    ],
    name: 'createPresalePrivate',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        components: [
          { internalType: 'address', name: 'creator', type: 'address' },
          { internalType: 'address', name: 'tokenAddress', type: 'address' },
          { internalType: 'uint256', name: 'tokenPrice', type: 'uint256' },
          { internalType: 'uint256', name: 'hardCap', type: 'uint256' },
          { internalType: 'uint256', name: 'softCap', type: 'uint256' },
          { internalType: 'uint256', name: 'openTime', type: 'uint256' },
          { internalType: 'uint256', name: 'closeTime', type: 'uint256' },
          { internalType: 'address', name: 'unsoldTokenToAddress', type: 'address' },
        ],
        internalType: 'struct IStructs.PresaleInfo',
        name: '_info',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'uint256', name: 'listingPrice', type: 'uint256' },
          { internalType: 'uint256', name: 'lpTokensLockDurationInDays', type: 'uint256' },
          { internalType: 'uint8', name: 'liquidityPercentageAllocation', type: 'uint8' },
          { internalType: 'uint256', name: 'liquidityAllocationTime', type: 'uint256' },
        ],
        internalType: 'struct IStructs.PresaleDexInfo',
        name: '_dexInfo',
        type: 'tuple',
      },
      {
        components: [
          { internalType: 'uint8', name: 'vestingPerc1', type: 'uint8' },
          { internalType: 'uint8', name: 'vestingPerc2', type: 'uint8' },
          { internalType: 'uint256', name: 'vestingPeriod', type: 'uint256' },
        ],
        internalType: 'struct IStructs.VestingInfo',
        name: '_vestInfo',
        type: 'tuple',
      },
    ],
    name: 'createPresalePublic',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getFeeParams',
    outputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }],
    name: 'getRoleAdmin',
    outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'user', type: 'address' }],
    name: 'getUserPresales',
    outputs: [{ internalType: 'address[]', name: '', type: 'address[]' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'grantRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'hasRole',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'inqubatorMaster',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'sender', type: 'address' }],
    name: 'isApprover',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'sender', type: 'address' }],
    name: 'isBackend',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'privateMaster',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'publicMaster',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'renounceRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'bytes32', name: 'role', type: 'bytes32' },
      { internalType: 'address', name: 'account', type: 'address' },
    ],
    name: 'revokeRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'receiver', type: 'address' },
      { internalType: 'uint256', name: 'perc', type: 'uint256' },
    ],
    name: 'setFeeParams',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '_public', type: 'address' },
      { internalType: 'address', name: '_private', type: 'address' },
      { internalType: 'address', name: '_inqubator', type: 'address' },
    ],
    name: 'setMasterAddresses',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }],
    name: 'supportsInterface',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: '', type: 'address' },
      { internalType: 'uint256', name: '', type: 'uint256' },
    ],
    name: 'userPresales',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
];
