import { FC, useCallback, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import BigNumber from 'bignumber.js';
import cn from 'classnames';
import { millisecondsToSeconds } from 'date-fns';
import Tooltip from 'rc-tooltip';

import { Button, CongratulationModal, Progress, StatusLabel, Timer } from 'components';
import { BROKEN_PRESALE_ADDRESS } from 'config';
import { addressWithDots, calculatePercentOfVotes, calculatePoolSize } from 'utils';
import { socialsImgs } from 'utils/constants';

import { InvestModal, UnableToVoteModal } from '../index';

import { useModal, useProjectStatusText } from 'hooks';
import { useWalletConnectorContext } from 'services';
import { IPresale, socialsNameEnum, statusEnum } from 'types';

import { ConfirmationModal } from '../../Voting';

// mock
import BscImg from 'assets/img/icons/chains/bsc.svg';
import copyLink from 'assets/img/icons/copy_link-gray.svg';
import { ReactComponent as DislikeImg } from 'assets/img/icons/dislike-gray.svg';
import { ReactComponent as LikeImg } from 'assets/img/icons/like-gray.svg';

import style from './ProjectControls.module.scss';

interface ISocial {
  items: {
    [key in socialsNameEnum]?: string;
  };
}

interface IProjectControlsProps {
  presale: IPresale;
  handleRefreshData: () => void;
  presaleLoading: boolean;
}

const diamondStageMultipliers = [0.3, 0.9, 2, 3];

const Socials: FC<ISocial> = ({ items }) => {
  return (
    <div className={style.p_controls__socials}>
      <div className={cn(style.p_controls__socials__title, 'text-sm text-gray')}>Social</div>
      <div className={style.p_controls__socials__box}>
        {Object.keys(items).map((item) => (
          <a
            href={items[item as socialsNameEnum]}
            key={item}
            target="_blank"
            rel="noreferrer"
            className={style.p_controls__socials__item}
          >
            <img src={socialsImgs[item as socialsNameEnum]} alt="" />
          </a>
        ))}
      </div>
    </div>
  );
};

const ProjectControls: FC<IProjectControlsProps> = observer(
  ({ presale, handleRefreshData, presaleLoading }) => {
    const {
      user,
      modals: { walletConnect },
    } = useMst();
    const { walletService } = useWalletConnectorContext();

    const [isUnableToVoteModalOpen, handleOpenUnableToVoteModal, handleCloseUnableToVoteModal] =
      useModal(false);
    const [isConfirmationModalOpen, handleOpenConfirmationModal, handleCloseConfirmationModal] =
      useModal(false);
    const [
      isVisibleCongratulationModal,
      handleOpenCongratulationModal,
      handleCloseCongratulationModal,
    ] = useModal(false);
    const [isVisibleInvestModal, handleOpenInvestModal, handleCloseInvestModal] = useModal(false);

    const [statusText, isRefreshingBetweenRounds] = useProjectStatusText(
      presale.type,
      presale.status,
      presale.round,
      presale.stage,
      presale.roundBreak,
      diamondStageMultipliers,
      presale.moonEnd,
      presale.diamondEnd,
      presale.paperEnd,
      presale.stagesEnd,
      handleRefreshData,
      presale.address,
    );

    const [isYesVote, setYesVote] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isVoteDisabled, setVoteDisabled] = useState(false);
    const [isRegisterDisabled, setRegisterDisabled] = useState(false);
    const [congratsText, setCongratsText] = useState('');
    const [contract] = useState(`PRESALE_${presale.type.toUpperCase()}`);

    const handleConnect = useCallback(() => {
      walletConnect.toggle();
    }, [walletConnect]);

    const beforeCloseCongratulationModal = useCallback(() => {
      handleRefreshData();
      handleCloseCongratulationModal();
    }, [handleCloseCongratulationModal, handleRefreshData]);

    const beforeCloseInvestModal = useCallback(() => {
      handleRefreshData();
      handleCloseInvestModal();
    }, [handleCloseInvestModal, handleRefreshData]);

    const handleVoteClick = useCallback(
      (vote: boolean) => {
        if (user.level === 0) {
          handleOpenUnableToVoteModal();
        } else {
          setYesVote(vote);
          handleOpenConfirmationModal();
        }
      },
      [handleOpenConfirmationModal, handleOpenUnableToVoteModal, user.level],
    );

    const handleVote = useCallback(async () => {
      handleCloseConfirmationModal();
      try {
        setLoading(true);
        await walletService.createTransaction({
          method: 'vote',
          contract,
          contractAddress: presale.address,
          data: [isYesVote],
        });
        setVoteDisabled(true);
        handleRefreshData();
        setLoading(false);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        setVoteDisabled(false);
      }
    }, [
      contract,
      handleCloseConfirmationModal,
      handleRefreshData,
      isYesVote,
      presale.address,
      walletService,
    ]);

    const handleRegister = useCallback(async () => {
      try {
        setLoading(true);
        await walletService.createTransaction({
          method: 'register',
          contract,
          contractAddress: presale.address,
          data: [],
        });
        setCongratsText('Congratulations! You’ve successfully registered for this IDO.');
        handleOpenCongratulationModal();
        setRegisterDisabled(true);
        handleRefreshData();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        setLoading(false);
      }
    }, [
      contract,
      handleOpenCongratulationModal,
      handleRefreshData,
      presale.address,
      walletService,
    ]);

    const handleCreateTransaction = useCallback(
      async (contractMethod, successText) => {
        try {
          setLoading(true);
          await walletService.createTransaction({
            method: contractMethod,
            contract,
            contractAddress: presale.address,
            data: [],
          });
          handleRefreshData();
          setCongratsText(successText);
          handleOpenCongratulationModal();
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log(err);
        } finally {
          setLoading(false);
        }
      },
      [contract, handleOpenCongratulationModal, handleRefreshData, presale.address, walletService],
    );
    useEffect(() => {
      console.log(presale);
    }, [presale]);

    return (
      <div className={cn(style.p_controls)}>
        <div className={style.p_controls__head}>
          <div className={style.p_controls__head__img}>
            {presale.logo !== '' && <img src={presale.logo} alt="" />}
          </div>
          <div className={style.p_controls__head__status}>
            <StatusLabel text={presale.type} />
          </div>
          <div className={style.p_controls__head__status}>
            <StatusLabel text={statusText[0]} />
          </div>
        </div>
        <div className={style.p_controls__head__wrapper}>
          <div className={cn(style.p_controls__head__name, 'text-md text-ellipsis text-md-tablet')}>
            {presale.title}
          </div>
          <div className={cn(style.p_controls__head__tags, 'text-gray text-sm text-sm-mobile')}>
            <div className={style.p_controls__head__tags__item}>Binance Smart Chain</div>
            <CopyToClipboard text={presale.address}>
              <div
                className={cn(
                  style.p_controls__head__tags__item,
                  style.p_controls__head__tags__item__address,
                )}
              >
                {addressWithDots(presale.tokenAddress)}
                <img src={copyLink} alt="" />
              </div>
            </CopyToClipboard>
            <a
              href={presale.socials.web}
              target="_blank"
              rel="noreferrer"
              className={style.p_controls__head__tags__item}
            >
              Website
            </a>
          </div>
        </div>
        {presale.status === statusEnum.open &&
          user.level > 0 &&
          user.level < 4 &&
          presale.user.isUserWinLottery &&
          presale.round === 'moon' && (
            <div className={style.p_controls__lottery}>
              Congratulations, You win Allocation for Moon Round
            </div>
          )}
        <div className={style.p_controls__progress}>
          <Progress
            all={+presale.hardCap}
            totalRaised={+presale.totalRaised}
            name={statusText[1]}
            titleClass={cn(style.p_controls__progress__title, 'text-upper', style[statusText[1]])}
          />
          <div className={style.p_controls__progress__goal}>
            <div className={style.p_controls__progress__goal__raised}>
              <img src={BscImg} alt="" />
              <span className="text-slg text-slg-tablet text-orange text-upper">{`${presale.totalRaised} USDT`}</span>
            </div>
            <div
              className={cn(style.p_controls__progress__goal__all, 'text-gray text-smd')}
            >{`Goals ${presale.hardCap} USDT`}</div>
          </div>
        </div>

        {presale.status === statusEnum.open && (
          <div className={style.p_controls__info}>
            <div className={style.p_controls__info__item_full}>
              <div className={cn(style.p_controls__info__item__title, 'text-gray text-sm')}>
                {+presale.stage === 4
                  ? 'Your Allocation'
                  : `Your allocation for Diamond Round Stage ${+presale.stage + 1}`}
              </div>
              <div
                className={cn(
                  style.p_controls__info__item__content,
                  'text-orange text-lmd text-600 text-upper',
                )}
              >
                {presale.user.investments.maxUserInvestment}
              </div>
            </div>
          </div>
        )}

        <div className={style.p_controls__info}>
          <div className={style.p_controls__info__item}>
            <div className={cn(style.p_controls__info__item__title, 'text-gray text-sm')}>
              Pool Size
            </div>
            <Tooltip
              placement="bottom"
              destroyTooltipOnHide
              overlay={
                <div
                  className={cn(
                    style.p_controls__info__item__content,
                    'text-orange text-lmd text-600 text-upper',
                  )}
                >
                  {calculatePoolSize(presale.hardCap, presale.tokenPrice)} {presale.tokenSymbol}
                </div>
              }
            >
              <div
                className={cn(
                  style.p_controls__info__item__content,
                  'text-orange text-lmd text-600 text-upper',
                )}
              >
                {calculatePoolSize(presale.hardCap, presale.tokenPrice)} {presale.tokenSymbol}
              </div>
            </Tooltip>
          </div>
          <div className={style.p_controls__info__item}>
            <div className={cn(style.p_controls__info__item__title, 'text-gray text-sm')}>
              Hard Cap
            </div>
            <Tooltip
              placement="bottom"
              destroyTooltipOnHide
              overlay={
                <div
                  className={cn(
                    style.p_controls__info__item__content,
                    'text-orange text-lmd text-600 text-upper',
                  )}
                >
                  {presale.hardCap} USDT
                </div>
              }
            >
              <div
                className={cn(
                  style.p_controls__info__item__content,
                  'text-orange text-lmd text-600 text-upper',
                )}
              >
                {presale.hardCap} USDT
              </div>
            </Tooltip>
          </div>
          <div className={style.p_controls__info__item}>
            <div className={cn(style.p_controls__info__item__title, 'text-gray text-sm')}>
              Token Price
            </div>
            <Tooltip
              placement="bottom"
              destroyTooltipOnHide
              overlay={
                <div
                  className={cn(
                    style.p_controls__info__item__content,
                    'text-orange text-lmd text-600 text-upper',
                  )}
                >
                  {presale.tokenPrice} BUSD
                </div>
              }
            >
              <div
                className={cn(
                  style.p_controls__info__item__content,
                  'text-orange text-lmd text-600 text-upper',
                )}
              >
                {presale.tokenPrice} USDT
              </div>
            </Tooltip>
          </div>
        </div>

        {presale.socials ? <Socials items={presale.socials} /> : null}

        {!presaleLoading && (
          <>
            {(presale.status === statusEnum.voting || presale.status === statusEnum.votingFailed) &&
            presale.address !== BROKEN_PRESALE_ADDRESS ? (
              <div className={style.p_controls__info}>
                <div className={style.p_controls__info__item_full}>
                  <div className={cn(style.p_controls__info__item__title, 'text-gray text-sm')}>
                    Votes will end in
                  </div>
                  <Timer
                    deadline={+presale.closeTimeVoting}
                    inDays
                    size="medium"
                    presaleStatus={presale.status}
                    customCallback={handleRefreshData}
                  />
                </div>
                {presale.type === 'public' && (
                  <div className={style.p_controls__info__item_lg}>
                    <div className={cn(style.p_controls__info__item__title, 'text-gray text-sm')}>
                      Total votes
                    </div>
                    <div className={style.p_controls__info__item_lg__content}>
                      {presale.totalVoters}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className={style.p_controls__info}>
                <div className={style.p_controls__info__item_full}>
                  <div className={cn(style.p_controls__info__item__title, 'text-gray text-sm')}>
                    {presale.status === statusEnum.upcoming &&
                      presale.type !== 'private' &&
                      'Registration Opens in:'}
                    {presale.status === statusEnum.upcoming &&
                      presale.type === 'private' &&
                      'Sale Opens in:'}
                    {presale.status === statusEnum.register && 'Registration Closed in:'}
                    {presale.status === statusEnum.registerClosed && 'Sale Opens in:'}
                    {presale.status === statusEnum.open && 'IDO ends in:'}
                    {presale.status === statusEnum.completedSuccess &&
                      presale.liquidityAdded !== 0 &&
                      user.address &&
                      user.address.toLowerCase() === presale.creator.toLowerCase() &&
                      !presale.isCreatorWithdrawFunds &&
                      'You can claim LP tokens only after providing liquidity'}
                  </div>
                  {presale.status === statusEnum.upcoming && (
                    <Timer
                      deadline={
                        presale.type === 'private' ? presale.salesStart : presale.registrationOpens
                      }
                      inDays
                      size="medium"
                      presaleStatus={presale.status}
                      customCallback={handleRefreshData}
                    />
                  )}
                  {presale.status === statusEnum.register && (
                    <Timer
                      deadline={presale.registrationClosed}
                      inDays
                      size="medium"
                      presaleStatus={presale.status}
                      customCallback={handleRefreshData}
                    />
                  )}
                  {presale.status === statusEnum.registerClosed && (
                    <Timer
                      deadline={presale.salesStart}
                      inDays
                      size="medium"
                      presaleStatus={presale.status}
                      customCallback={handleRefreshData}
                    />
                  )}
                  {presale.status === statusEnum.open && (
                    <>
                      <Timer
                        deadline={presale.salesEnd}
                        inDays
                        size="medium"
                        presaleStatus={presale.status}
                        customCallback={handleRefreshData}
                      />
                    </>
                  )}
                  {presale.status === statusEnum.completedSuccess &&
                    user.address &&
                    user.address.toLowerCase() === presale.creator.toLowerCase() &&
                    presale.liquidityAdded !== 0 &&
                    !presale.isCreatorWithdrawFunds && (
                      <Timer
                        deadline={+presale.liquidityAdded * 1000}
                        inDays
                        size="medium"
                        presaleStatus={presale.status}
                        customCallback={handleRefreshData}
                      />
                    )}
                </div>
                {presale.type === 'public' && presale.status === statusEnum.upcoming && (
                  <div className={style.p_controls__info__item_lg}>
                    <div className={cn(style.p_controls__info__item__title, 'text-gray text-sm')}>
                      Total votes
                    </div>
                    <div className={style.p_controls__info__item_lg__content}>
                      {presale.totalVoters}
                    </div>
                  </div>
                )}
              </div>
            )}

            {user.address ? (
              <>
                {presale.status === statusEnum.completedSuccess &&
                  user.address.toLowerCase() !== presale.creator.toLowerCase() &&
                  presale.user.investments.canUserClaim && (
                    <div className={style.p_controls__info}>
                      <div className={style.p_controls__info__item_full}>
                        <div
                          className={cn(style.p_controls__info__item__title, 'text-gray text-sm')}
                        >
                          Claimed
                        </div>
                        <div className={style.p_controls__info__item_full__content}>
                          {presale.user.investments.amountClaimed} Token
                        </div>
                        <div
                          className={cn(
                            style.p_controls__info__item__title_mt,
                            'text-gray text-sm',
                          )}
                        >
                          of
                        </div>
                        <div className={style.p_controls__info__item_full__content}>
                          {presale.user.investments.amountTokens} Token
                        </div>
                      </div>
                      <div className={style.claim_info}>
                        <div className={style.p_controls__info__item_lg}>
                          <div
                            className={cn(
                              style.p_controls__info__item__title_mb,
                              'text-gray text-sm',
                            )}
                          >
                            Claimed
                          </div>
                        </div>
                        <div className={style.p_controls__info__item_lg}>
                          <div
                            className={cn(
                              style.p_controls__info__item__title_align,
                              'text-gray text-sm',
                            )}
                          >
                            {presale.user.investments.amountClaimed}
                          </div>
                        </div>
                        <div className={style.p_controls__info__item_lg}>
                          <div
                            className={cn(
                              style.p_controls__info__item__title_mb,
                              'text-gray text-sm',
                            )}
                          >
                            Ready to claim
                          </div>
                        </div>
                        <div className={style.p_controls__info__item_lg}>
                          <div
                            className={cn(
                              style.p_controls__info__item__title_align,
                              'text-gray text-sm',
                            )}
                          >
                            {presale.user.investments.readyToClaim}
                          </div>
                        </div>
                        <div className={style.p_controls__info__item_lg}>
                          <div
                            className={cn(
                              style.p_controls__info__item__title_mb,
                              'text-gray text-sm',
                            )}
                          >
                            Locked Balance
                          </div>
                        </div>
                        <div className={style.p_controls__info__item_lg}>
                          <div
                            className={cn(
                              style.p_controls__info__item__title_align,
                              'text-gray text-sm',
                            )}
                          >
                            {+presale.user.investments.amountClaimed === 0 &&
                              new BigNumber(presale.user.investments.amountTokens)
                                .minus(presale.user.investments.readyToClaim)
                                .toString()}
                            {+presale.user.investments.amountClaimed > 0 &&
                              +presale.user.investments.readyToClaim === 0 &&
                              new BigNumber(presale.user.investments.amountTokens)
                                .minus(presale.user.investments.amountClaimed)
                                .toString()}
                            {+presale.user.investments.amountClaimed > 0 &&
                              +presale.user.investments.readyToClaim > 0 &&
                              new BigNumber(presale.user.investments.amountTokens)
                                .minus(presale.user.investments.amountClaimed)
                                .minus(presale.user.investments.readyToClaim)
                                .toString()}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {presale.status === statusEnum.voting && (
                  <div className={style.p_controls__btns_vote}>
                    <Button
                      color="green"
                      className={cn(style.p_controls__btn)}
                      onClick={() => handleVoteClick(true)}
                      size="medium"
                      loading={isLoading}
                      disabled={
                        presale.user.isUserVoted ||
                        isVoteDisabled ||
                        user.address.toLowerCase() === presale.creator.toLowerCase() ||
                        !presale.isInit
                      }
                    >
                      <LikeImg />
                      {`${calculatePercentOfVotes(presale.voters[0], presale.totalVoters)}%`}
                    </Button>
                    <Button
                      color="red"
                      className={cn(style.p_controls__btn_vote)}
                      onClick={() => handleVoteClick(false)}
                      size="medium"
                      loading={isLoading}
                      disabled={
                        presale.user.isUserVoted ||
                        isVoteDisabled ||
                        user.address.toLowerCase() === presale.creator.toLowerCase() ||
                        !presale.isInit
                      }
                    >
                      <DislikeImg />
                      {`${calculatePercentOfVotes(presale.voters[1], presale.totalVoters)}%`}
                    </Button>
                  </div>
                )}

                {user.address.toLowerCase() !== presale.creator.toLowerCase() && (
                  <>
                    {presale.status === statusEnum.upcoming && (
                      <Button
                        color="filled"
                        className={cn(style.p_controls__btn)}
                        onClick={() => {}}
                        disabled
                      >
                        {presale.type === 'private' ? 'Buy' : 'Register now'}
                      </Button>
                    )}

                    {presale.status === statusEnum.register && user.level > 0 && (
                      <Button
                        color="filled"
                        className={cn(style.p_controls__btn)}
                        disabled={presale.user.isUserRegistered || isRegisterDisabled}
                        onClick={handleRegister}
                        loading={isLoading}
                      >
                        {presale.user.isUserRegistered || isRegisterDisabled
                          ? 'You already registered'
                          : 'Register now'}
                      </Button>
                    )}
                  </>
                )}

                {presale.status === statusEnum.register && user.level === 0 && (
                  <Button color="filled" className={cn(style.p_controls__btn)} href="/levels">
                    Stake for levels
                  </Button>
                )}

                {presale.status === statusEnum.open && (
                  <Button
                    color="filled"
                    className={cn(style.p_controls__btn)}
                    onClick={handleOpenInvestModal}
                    disabled={!presale.user.investments.canUserInvest || isRefreshingBetweenRounds}
                  >
                    {isRefreshingBetweenRounds ? 'Refreshing...' : 'Invest now'}
                  </Button>
                )}

                {(presale.status === statusEnum.completedFail ||
                  presale.status === statusEnum.votingFailed) &&
                  user.address.toLowerCase() === presale.creator.toLowerCase() &&
                  +presale.tokensForSaleLeft > 0 && (
                    <Button
                      color="filled"
                      className={cn(style.p_controls__btn)}
                      disabled={presale.isCreatorReturnTokens}
                      onClick={() =>
                        handleCreateTransaction(
                          'withdrawTokens',
                          'Your tokens are returned successfully.',
                        )
                      }
                      loading={isLoading}
                    >
                      Return tokens
                    </Button>
                  )}

                {presale.status === statusEnum.completedFail &&
                  user.address.toLowerCase() !== presale.creator.toLowerCase() &&
                  +presale.user.investments.amountEth > 0 && (
                    <Button
                      color="filled"
                      className={cn(style.p_controls__btn)}
                      onClick={() =>
                        handleCreateTransaction(
                          'withdrawInvestment',
                          'Congratulations! Your refund is successful.',
                        )
                      }
                      loading={isLoading}
                    >
                      Claim Refund
                    </Button>
                  )}

                {/* For creator */}
                {presale.status === statusEnum.completedSuccess &&
                  user.address.toLowerCase() === presale.creator.toLowerCase() && (
                    <>
                      {+presale.liquidityAdded !== 0 ? (
                        <>
                          {!presale.isCreatorWithdrawFunds && (
                            <Button
                              color="filled"
                              className={cn(style.p_controls__btn)}
                              disabled={presale.isCreatorWithdrawFunds}
                              onClick={() =>
                                handleCreateTransaction(
                                  'claimRaisedFunds',
                                  'Congratulations! You have collected the funds for your project development!',
                                )
                              }
                              loading={isLoading}
                            >
                              Collect funds
                            </Button>
                          )}
                          {!presale.isCreatorClaimedLp && (
                            <Button
                              color="filled"
                              className={cn(style.p_controls__btn)}
                              disabled={
                                millisecondsToSeconds(Date.now()) < +presale.liquidityAdded ||
                                presale.isCreatorClaimedLp
                              }
                              onClick={() =>
                                handleCreateTransaction(
                                  'claimLps',
                                  'Congratulations! You’ve successfully claimed your LP tokens!',
                                )
                              }
                              loading={isLoading}
                            >
                              Claim LP tokens
                            </Button>
                          )}
                        </>
                      ) : (
                        <Button
                          color="filled"
                          className={cn(style.p_controls__btn)}
                          disabled={presale.addLiquidityLocked}
                          onClick={() =>
                            handleCreateTransaction(
                              'addLiquidity',
                              'The liquidity is successfully locked.',
                            )
                          }
                          loading={isLoading}
                        >
                          Add Liquidity
                        </Button>
                      )}
                    </>
                  )}

                {/* For user */}
                {presale.status === statusEnum.completedSuccess &&
                  user.address.toLowerCase() !== presale.creator.toLowerCase() &&
                  presale.user.investments.amountClaimed !==
                    presale.user.investments.amountTokens && (
                    <Button
                      color="filled"
                      className={cn(style.p_controls__btn)}
                      disabled={
                        presale.liquidityAdded === 0 || !presale.user.investments.canUserClaim
                      }
                      onClick={() =>
                        handleCreateTransaction(
                          'claimTokens',
                          'Congratulations! You’ve successfully claimed your tokens.',
                        )
                      }
                      loading={isLoading}
                    >
                      Claim Tokens
                    </Button>
                  )}
              </>
            ) : (
              <Button color="filled" className={cn(style.p_controls__btn)} onClick={handleConnect}>
                Connect wallet
              </Button>
            )}
          </>
        )}
        <div className={cn(style.p_controls__verify, 'text-smd')}>
          This team has passed <span className="text-bold">KYC verification</span>
        </div>
        <ConfirmationModal
          visible={isConfirmationModalOpen}
          onClose={handleCloseConfirmationModal}
          handleVote={handleVote}
        />
        <UnableToVoteModal
          visible={isUnableToVoteModalOpen}
          onClose={handleCloseUnableToVoteModal}
        />
        <CongratulationModal
          text={congratsText}
          visible={isVisibleCongratulationModal}
          onClose={beforeCloseCongratulationModal}
          btnText="Confirm"
          btnAction={handleCloseCongratulationModal}
        />
        <InvestModal
          tokenPrice={presale.tokenPrice}
          tokenSymbol={presale.tokenSymbol}
          tokenDecimals={presale.tokenDecimals}
          maxInvestment={presale.user.investments.maxUserInvestment}
          presaleType={presale.type}
          presaleAddress={presale.address}
          visible={isVisibleInvestModal}
          onClose={beforeCloseInvestModal}
          handleOpenCongratulationModal={handleOpenCongratulationModal}
        />
      </div>
    );
  },
);

export default ProjectControls;
