export const levels = [
  {
    name: 'Newbie',
    value: '100000000000',
    weight: 0.5,
    lottery: 15,
  },
  {
    name: 'Boy Sminem',
    value: '500000000000',
    weight: 1,
    lottery: 35,
  },
  {
    name: 'Enthusiast',
    value: '2000000000000',
    weight: 2.5,
    lottery: 80,
  },
  {
    name: 'Adventurer',
    value: '5500000000000',
    weight: 5.5,
  },
  {
    name: 'Risk Taker',
    value: '12000000000000',
    weight: 12,
  },
  {
    name: 'Maximalist',
    value: '19000000000000',
    weight: 19,
  },
  {
    name: 'Quapitalist',
    value: '26000000000000',
    weight: 26,
  },
  {
    name: 'Degen',
    value: '70000000000000',
    weight: 70,
  },
  {
    name: 'Saint Bog',
    value: '150000000000000',
    weight: 150,
  },
];
