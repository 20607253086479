import React from 'react';

import { observer } from 'mobx-react-lite';
import { useMst } from 'store';

import { LevelsHarvest, LevelsPreview, LevelsSlider, LevelsStake, LevelsSteps } from 'containers';

import { levels } from './mock';

import s from './Levels.module.scss';

const Levels: React.FC = observer(() => {
  const { user } = useMst();

  return (
    <div className={s.levels}>
      <LevelsPreview />
      <LevelsSteps items={levels} balance={user.totalStaked} />
      <LevelsHarvest />
      <LevelsSlider items={levels} userLevel={user.level} />
      <LevelsStake items={levels} />
    </div>
  );
});

export default Levels;
