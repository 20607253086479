import { useCallback, useEffect, useState, VFC } from 'react';

import BigNumber from 'bignumber.js';
import cn from 'classnames';

import { contracts } from 'config';
import { convertQuackTokens } from 'utils';

import { quackApi, useWalletConnectorContext, WalletService } from 'services';

import s from './LevelsPreview.module.scss';

const LevelsPreview: VFC = () => {
  const { walletService } = useWalletConnectorContext();
  const [totalStaked, setTotalStaked] = useState('0');
  const [tokenPrice, setTokenPrice] = useState('0');

  const calculateTotalStaked = useCallback(async () => {
    const tokenDecimals = await walletService.getTokenDecimals(
      contracts.params.QUACK[contracts.type].address,
    );
    quackApi
      .getQuackData()
      .then(({ data }) => {
        setTokenPrice(data.price);
      })
      .catch((err) => console.log(err));
    const total = await walletService.callContractMethod({
      contractName: 'STAKING',
      methodName: 'totalStaked',
      contractAddress: contracts.params.STAKING[contracts.type].address,
      contractAbi: contracts.params.STAKING[contracts.type].abi,
    });
    return new BigNumber(WalletService.weiToEth(total, tokenDecimals))
      .multipliedBy(tokenPrice)
      .toFixed(2);
  }, [tokenPrice, walletService]);

  useEffect(() => {
    calculateTotalStaked().then((res) => {
      setTotalStaked(res);
    });
  }, [calculateTotalStaked]);

  return (
    <div className={s.l_preview}>
      <div className={s.l_preview__text}>
        <div className={s.l_preview__title}>Stake for LaunchPAD Participation</div>
        <div className={cn(s.l_preview__subtitle, 'text-gray')}>
          Pool presale participation for the Guaranteed Allocation model is broken down into 9
          Levels based on the number of QUACK you have staked.
        </div>
      </div>
      <div className={s.l_preview__box}>
        <div className={s.l_preview__box__title}>Total Value Staked</div>
        <div className={cn(s.l_preview__box__value, 'text-orange')}>
          ${convertQuackTokens(totalStaked)}
        </div>
      </div>
    </div>
  );
};

export default LevelsPreview;
