import { INetwork } from '@amfi/connect-wallet/dist/interface';

import { chainsEnum, IConnectWallet, IContracts } from 'types';

import {
  bep20Abi,
  factoryAbi,
  presaleInqubatorAbi,
  presalePrivateAbi,
  presalePublicAbi,
  stakingAbi,
  stakingOldAbi,
  votingAbi,
} from './abi';

export const is_production = true;

export const chains: {
  [key: string]: {
    name: chainsEnum;
    network: INetwork;
    provider: {
      [key: string]: any;
    };
    explorer: string;
  };
} = {
  [chainsEnum['Binance-Smart-Chain']]: {
    name: chainsEnum['Binance-Smart-Chain'],
    network: {
      chainID: is_production ? 56 : 97,
      chainName: is_production ? 'Binance Smart Chain' : 'Binance Smart Chain Testnet',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpc: is_production
        ? 'https://bsc-dataseed.binance.org/'
        : 'https://bsc-testnet.public.blastapi.io',
      blockExplorerUrl: is_production ? 'https://bscscan.com' : 'https://testnet.bscscan.com',
    },
    provider: {
      MetaMask: { name: 'MetaMask' },
      WalletConnect: {
        img: '',
        name: 'WalletConnect',
        useProvider: 'rpc',
        provider: {
          rpc: {
            rpc: {
              [is_production ? 56 : 97]: is_production
                ? 'https://bsc-dataseed.binance.org/'
                : 'https://data-seed-prebsc-2-s1.binance.org:8545/',
            },
            chainId: is_production ? 56 : 97,
            wcConfig: {
              chains: [is_production ? 56 : 97],
              methods: ['eth_sendTransaction', 'eth_signTypedData_v4', 'eth_sign', 'personal_sign'],
              showQrModal: true,
              projectId: process.env.REACT_APP_WC_PROJECT_ID,
              qrModalOptions: {
                themeVariables: {
                  '--wcm-z-index': '9999',
                },
              },
            },
          },
        },
      },
    },
    explorer: is_production ? 'https://bscscan.com' : 'https://testnet.bscscan.com',
  },
};

export const connectWallet = (chainName: chainsEnum): IConnectWallet => {
  const chain = chains[chainName];

  return {
    wallets: ['MetaMask'],
    network: chain.network,
    provider: chain.provider,
    settings: { providerType: true },
  };
};

export const contracts: IContracts = {
  type: is_production ? 'mainnet' : 'testnet',
  names: [
    'USDT',
    'QUACK',
    'STAKING',
    'PRESALE_PUBLIC_TEST',
    'PRESALE_PUBLIC',
    'PRESALE_PRIVATE',
    'PRESALE_INQUBATOR',
    'FACTORY',
    'STAKING_OLD',
    'VOTING',
  ],
  params: {
    USDT: {
      mainnet: {
        address: '0x55d398326f99059fF775485246999027B3197955',
        abi: bep20Abi,
      },
      testnet: {
        address: '0x78867BbEeF44f2326bF8DDd1941a4439382EF2A7',
        abi: bep20Abi,
      },
    },
    LINK: {
      mainnet: {
        address: '0x404460C6A5EdE2D891e8297795264fDe62ADBB75',
        abi: bep20Abi,
      },
      testnet: {
        address: '0x84b9B910527Ad5C03A9Ca831909E21e236EA7b06',
        abi: bep20Abi,
      },
    },
    QUACK: {
      mainnet: {
        address: '0xD74b782E05AA25c50e7330Af541d46E18f36661C',
        abi: bep20Abi,
      },
      testnet: {
        address: '0x452d9e4341519811cc4c25e940f477f53ba0dea1',
        abi: bep20Abi,
      },
    },
    STAKING: {
      mainnet: {
        address: '0x24E1FB7a781d255EdC40e80C89d9289dC61925F2',
        abi: stakingAbi,
      },
      testnet: {
        address: '0xF33A33a5B6be9AD06d2a61dD650CE2331bBfB46a',
        abi: stakingAbi,
      },
    },
    PRESALE_PUBLIC_TEST: {
      mainnet: {
        address: '0xc4C546cC2A6D577c8B180A8A071ED96050CcE2DF', // temp,
        abi: presalePublicAbi,
      },
      testnet: {
        address: '0x455472e44245aE9c762dA3748D423908a8d71580',
        abi: presalePublicAbi,
      },
    },
    PRESALE_PUBLIC: {
      mainnet: {
        address: '0xc4C546cC2A6D577c8B180A8A071ED96050CcE2DF',
        abi: presalePublicAbi,
      },
      testnet: {
        address: '0x38E3fEf78Ed52BC8EC86a7b87a8D8729c516c691',
        abi: presalePublicAbi,
      },
    },
    PRESALE_PRIVATE: {
      mainnet: {
        address: '0x46444F80Df6D939A34322047a60c7FF5546Fc2A6', // temp
        abi: presalePrivateAbi,
      },
      testnet: {
        address: '0x5B731BDEEEfe7cC80E7E40aE3Dfb7Caf7076E696',
        abi: presalePrivateAbi,
      },
    },
    PRESALE_INQUBATOR: {
      mainnet: {
        address: '0x17170D3229906D690FC37ad13891492f16977ecB', // temp
        abi: presaleInqubatorAbi,
      },
      testnet: {
        address: '0xD87DD9C68024D3C94d5AA1DAFD9337bD3f84c73b',
        abi: presaleInqubatorAbi,
      },
    },
    FACTORY: {
      mainnet: {
        address: '0xf071A478c4A6962E5A9019e9E4486070bcD89178', // temp
        abi: factoryAbi,
      },
      testnet: {
        address: '0xFeCe7959Ee75Ad1321e84Ff86A5beD0B44Ec4Dc8',
        abi: factoryAbi,
      },
    },
    STAKING_OLD: {
      mainnet: {
        address: '0xfab0fd2586e287746aaec8397109b5fe6d2ff053',
        abi: stakingOldAbi,
      },
      testnet: {
        address: '0x3bEeA65fdf4C0C51055675800B142045Ed4c76A2',
        abi: stakingOldAbi,
      },
    },
    VOTING: {
      mainnet: {
        address: '0x4c86b4d36ecf2a92da3d7590c7259acb5c1c0c02',
        abi: votingAbi,
      },
      testnet: {
        address: '0xC290cB0e7f29D19CEfc1Ea62B6A9a5cCe3741e69',
        abi: votingAbi,
      },
    },
  },
};

export const BROKEN_PRESALE_ADDRESS = '0xa47F367a619DB0c55B9Fa45350c741AA80D52EFd'