import { cast, SnapshotOut, types } from 'mobx-state-tree';

const LockUpItem = types.model('LevelItem', {
  amountLock: types.string,
  enteredAt: types.string,
});

export type ILockUpItem = SnapshotOut<typeof LockUpItem>;

const UserModel = types
  .model({
    address: types.maybeNull(types.string),
    quackBalance: types.string,
    usdtBalance: types.string,
    level: types.number,
    totalStaked: types.string,
    lockUpItems: types.array(LockUpItem),
    refreshUserData: types.boolean,
  })
  .actions((self) => ({
    setAddress: (address: string) => {
      self.address = address;
    },
    setQuackBalance: (balance: string) => {
      self.quackBalance = balance;
    },
    setusdtBalance: (balance: string) => {
      self.usdtBalance = balance;
    },
    setLevel: (level: number, totalStaked: string) => {
      self.level = level;
      self.totalStaked = totalStaked;
    },
    setLockUps: (items: ILockUpItem[]) => {
      self.lockUpItems = cast(items);
    },
    setRefreshUserData: (value: boolean) => {
      self.refreshUserData = value;
    },
    disconnect: () => {
      self.address = null;
    },
  }));
export default UserModel;
